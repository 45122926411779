import React, { useEffect, useState } from "react";
import "./css/feedBack.scss";
import { AiOutlineStar, AiOutlineHeart, AiFillStar } from "react-icons/ai";
import Draggable from 'react-draggable';
import { IoMdCloseCircle } from "react-icons/io";
import { ClientFeedbackDetail,ResetFeedBack } from "../../actions/feedback/customerFeedBackAction";
import { ClientFeedbackReducer } from "../../reducers/feedback/customerFeedBackReducer";
import { getFeedbackStatusDetail } from "../../actions/feedback/feedbackStatusAction";
import { createClientFeedbackDetail } from "../../actions/feedback/createClientFeedbackAction";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const FeedbackForm = ({handleClose}) => {
  // State to manage the textarea value
  const [message, setMessage] = useState('');
  const [buttonLoading,setButtonLoading]=useState(false);
  const dispatch=useDispatch();
  const location=useLocation();
  //login 
  const loginCheck=localStorage.getItem('isLoggedIn');
  

  const feedbackStatus=useSelector(state=>state.ClientFeedbackReducer);
  useEffect(()=>{
    console.log(feedbackStatus);
  if(feedbackStatus){
    if(feedbackStatus?.response){
      setButtonLoading(false);
      setMessage('');
      handleClose();
      toast.success("FeedBack added successfully!");
      dispatch(ResetFeedBack());
    }
  }
  },[feedbackStatus])
  // Function to handle textarea value change
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const userAgents = navigator.userAgent;
    const pathName=location.pathname;
    // You can perform any action with the message here, like sending it to an API or displaying it
    console.log(message);
   const data={
    path: pathName,
    userAgent:userAgents,
    email: "nouser@gmail.com",
    comment:message
  };
  console.log(data);
  dispatch(ClientFeedbackDetail(data));
   setButtonLoading(true);
  };
  
  return (
    <div className="form">
         <span className="close-btn" onClick={handleClose}>
                  <IoMdCloseCircle onClick={handleClose}/>
         </span>
    <p className="form-text">Comments</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <textarea
            name="message"
            value={message}
            onChange={handleMessageChange}
            cols="30"
            rows="10"
            placeholder="Send us your feedback!"
            autoFocus
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary btn-sm btn-block">
        {buttonLoading? (
                    <Spinner animation="border" size="sm" variant="success" />
                  ):("Send it!")}
        </button>
      </form>
    </div>
  );
};

const FeedBack = () => {
    const [show,setShow]=useState(true);
    const [ShowForm,setShowForm]=useState(false);
    const handleFeedBack=()=>{
        setShow(false);
        setShowForm(true);
    }
    const handleClose=()=>{
        setShow(true);
        setShowForm(false);
    }
  return (
    <>
     {/* <Draggable
       axis="y"
       > */}
    <div className="feedback">
        {show &&
        <button id="popup" className="feedback-button" onClick={handleFeedBack}>FeedBack <AiFillStar /></button>
        }
       {ShowForm &&
       <FeedbackForm handleClose={handleClose}/>
       }
      </div>
      {/* </Draggable> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

export default FeedBack;
